<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Meal Restrictions ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="mb-2"
        @click="addMealRestrictionDialog = true"
      >
        <v-icon left> {{ icons.add }} </v-icon>Add Meal Restriction
      </v-btn>
    </v-app-bar>

    <div class="mt-12 mb-6">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            clearable
            hide-details
            label="Search"
            @input="doFilter"
            placeholder="Search by name"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :items="restrictions"
      :loading="loading"
      :headers="headers"
      class="elevation-1"
      :options.sync="options"
      :server-items-length="listMeta.total"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :items-per-page-options="[10, 20, 50, 100]"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ icons.dots }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                $router.push({
                  name: 'meal.restrictions.details',
                  params: { id: item.id }
                })
              "
            >
              <v-list-item-icon>
                <v-icon v-text="icons.edit"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteMeal(item.id)">
              <v-list-item-icon>
                <v-icon v-text="icons.delete"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>Remove</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <AddMealRestrictionDialog
      v-model="addMealRestrictionDialog"
      :refresh="getDataFromApi"
    ></AddMealRestrictionDialog>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ControlsMixin from '@/utils/mixins/Controls'
import { mdiPlus, mdiPencil, mdiDelete, mdiDotsVertical } from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import AddMealRestrictionDialog from './components/AddMealRestrictionDialog.vue'

export default {
  name: 'MealRestrictionsPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    AddMealRestrictionDialog
  },

  data() {
    return {
      options: {},
      loading: false,
      search: null,
      addMealRestrictionDialog: false,
      icons: {
        add: mdiPlus,
        edit: mdiPencil,
        delete: mdiDelete,
        dots: mdiDotsVertical
      },
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Initial',
          value: 'initial'
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '150px'
        }
      ]
    }
  },

  computed: {
    ...mapState({
      restrictions: state => state.restriction.list,
      listMeta: state => state.restriction.listMeta
    })
  },

  methods: {
    ...mapActions({
      getMealRestrictions: 'restriction/getMealRestrictions',
      deleteMealRestriction: 'restriction/deleteMealRestriction'
    }),

    ...mapMutations({
      clearMealRestrictionList: 'restriction/clearMealRestrictionList'
    }),

    doFilter: debounce(function() {
      this.getDataFromApi()
    }, 600),

    async getDataFromApi() {
      this.loading = true
      this.clearMealRestrictionList()
      await this.getMealRestrictions({
        search: this.search,
        ...this.options
      })
      this.loading = false
    },

    async deleteMeal(id) {
      await this.deleteMealRestriction(id)
      this.getDataFromApi()
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  }
}
</script>
